import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Payee Object">
      <div className="headline">
        <h1 className="text-primary m-0">Payee Object</h1>
      </div>
      <div className="items-group">
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">type</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>
              Value fixed to<span className="reference">payee</span>, represents
              payee object
            </p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">id</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Unique identifier of payee</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">merchant_id</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Unique identifier of merchant</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">outlet_id</span>
            <span className="field-type block-md">String, can be empty</span>
          </div>
          <div className="col-md-9">
            <p>Unique identifier of outlet</p>
            <span className="text-muted small">Can be empty</span>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">merchant_name</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Name of merchant</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">outlet_name</span>
            <span className="field-type block-md">String, can be empty</span>
          </div>
          <div className="col-md-9">
            <p>Name of outlet</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">display_name</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Name to be displayed</p>
            <p className="text-muted small">Either name of merchant or outlet</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">display_image_url</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>URL of image to be displayed</p>
          </div>
        </div>
      </div>
    </DocumentLayout>
  )
}
